import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import useTranslation from 'Hooks/useTranslation';

const ExportConfirmationModal = ({ 
    open, 
    onClose, 
    onConfirm, 
    onShowFilter, 
    activitiesCount 
}) => {
    const translation = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className="respModal  autoHeight"
        >
            <div className="modalTitleHolder mb16">
                <h1 className="modalTitle">
                    {'Export PDF'}
                </h1>
            </div>
            <p className='deleteItemLabel mb16'>
                {`Are you sure you want to export ${activitiesCount} activities?`}
            </p>
            <div className="btnHolder end mt10 fixedWidthBtn">
                <Button
                    onClick={onClose}
                    variant="outlined"
                >
                    {translation.cancelLabel || 'Cancel'}
                </Button>
                <Button
                    onClick={onShowFilter}
                    variant="outlined"
                >
                    {translation.showFilterLabel || 'Show Filter'}
                </Button>
                <Button
                    onClick={onConfirm}
                    variant="contained"
                    color="primary"
                >
                    {translation.yesLabel || 'Yes'}
                </Button>
            </div>
        </Dialog>
    );
};

export default ExportConfirmationModal;
